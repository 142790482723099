import { fetchWithTimeout } from '@/fetch.js';

export const URLPrefix = '/api/v1.0/';

export const getVersionInfo = async () => {
  const response = await fetchWithTimeout(`${URLPrefix}version/infos`, {
    method: 'GET',
  });
  const jsonData = await response.json();
  return jsonData.data[0];
};

export const getCurrentUser = async () => {
  const response = await fetchWithTimeout('/api/v1.0/currentuser', {
    method: 'GET',
  });
  const jsonData = await response.json();
  return jsonData.data[0];
};
