// ============================================================================
// Copyright 2021 ExoAplha. All rights reserved.
//
// Auhtor:        Alexandre COSTANTINI
// Filename:      router.js
// Created on:    2019/09/26
// Last modified: 2021/05/07
// ============================================================================

import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('./views/Home.vue'),
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('./views/Login.vue'),
    },
    {
      path: '/forgotpassword',
      name: 'forgotpassword',
      component: () => import('./views/ForgotPassword.vue'),
    },
    {
      path: '/privacypolicy',
      name: 'privacypolicy',
      component: () => import('./views/PrivacyPolicy.vue'),
    },
    {
      path: '/blocked',
      name: 'blocked',
      component: () => import('./views/Blocked.vue'),
    },
  ],
});
