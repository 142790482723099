<!--===========================================================================
 Copyright 2021 ExoAplha. All rights reserved.

 Auhtor:        Alexandre COSTANTINI
 Filename:      SpinningMessage.vue
 Created on:    2019/10/01
 Last modified: 2021/05/07
===========================================================================!-->

<!-- prettier-ignore -->
<template>
  <div class="ea-spinning my-2 text-center">
    <b-spinner label="Spinning" />
    <strong style="margin-left: 10px;">{{ message }}</strong>
  </div>
</template>

<script>
export default {
  name: 'SpinningMessage',
  props: {
    message: {
      type: String,
      required: true,
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.ea-spinning {
  color: var(--color-body-fg);
  font-size: 14px;
  font-weight: bold;
  padding: 5px;
}
</style>
