<!--===========================================================================
 Copyright 2021 ExoAplha. All rights reserved.

 Auhtor:        Alexandre COSTANTINI
 Filename:      CurrencySelector.vue
 Created on:    2020/01/13
 Last modified: 2021/05/07
===========================================================================!-->

<!-- prettier-ignore -->
<template>
  <b-dropdown
    id="currency-button"
    size="sm"
    toggle-class="py-0 my-0"
  >
    <template #button-content>
      <img
        :src="buildFlag(selected)"
        width="24px"
        :alt="selected"
      />
      {{ selectedValue }}
    </template>
    <div
      v-for="(value, index) in currencies"
      :key="index"
    >
      <b-dropdown-item-button
        v-if="buildFlag(value) !== ''"
        @click="onSelectedCurrency(Object.keys(value)[0])"
      >
        <img
          :src="buildFlag(value)"
          width="24px"
          :alt="value"
        />
        {{ Object.values(value)[0] }}
      </b-dropdown-item-button>
      <b-dropdown-divider v-else />
    </div>
  </b-dropdown>
</template>

<script>
import _ from 'lodash';

export default {
  name: 'CurrencySelector',
  props: {
    selectedCurrency: {
      type: String,
      required: false,
      default: 'usd',
    },
    availables: {
      type: Array,
      required: false,
      default: () => {
        return ['usd', 'eur', 'gbp', 'chf', 'cny', 'cad', 'jpy', 'rub'];
      },
    },
  },
  data() {
    const items = [
      { usd: 'USD' },
      { eur: 'EUR' },
      { gbp: 'GBP' },
      { chf: 'CHF' },
      { cny: 'CNY' },
      { cad: 'CAD' },
      { jpy: 'JPY' },
      { rub: 'RUB' },
      {},
      { btc: 'BTC' },
      { eth: 'ETH' },
    ];
    return {
      selected: this.selectedCurrency,
      currencies: _.filter(items, (item) =>
        _.find(this.availables, (elem) => elem === _.keys(item)[0])
      ),
    };
  },
  computed: {
    selectedValue () {
      let s = '';
      for (const item of this.currencies) {
        if (_.keys(item)[0] === this.selected) {
          s = _.values(item)[0];
          break;
        }
      }
      return s;
    },
  },
  watch: {
    selectedCurrency (val) {
      this.selected = val;
    },
  },
  methods: {
    onSelectedCurrency (currency) {
      if (this.selected !== currency) {
        this.selected = currency;
        this.$emit('update:selectedCurrency', currency);
      }
    },
    buildFlag (value) {
      let v;
      if (_.isObject(value)) {
        v = _.keys(value)[0];
      } else if (_.isString(value)) {
        v = value;
      }
      switch (v) {
        case 'usd':
          return 'images/flags/64/us.png';
        case 'eur':
          return 'images/flags/64/eu.png';
        case 'gbp':
          return 'images/flags/64/gb.png';
        case 'cad':
          return 'images/flags/64/ca.png';
        case 'chf':
          return 'images/flags/64/ch.png';
        case 'cny':
          return 'images/flags/64/cn.png';
        case 'jpy':
          return 'images/flags/64/jp.png';
        case 'rub':
          return 'images/flags/64/ru.png';
        case 'btc':
          return 'images/cryptos/64/BTC.png';
        case 'eth':
          return 'images/cryptos/64/ETH.png';
        default:
          return '';
      }
    },
  },
};
</script>
