// ============================================================================
// Copyright 2021 ExoAplha. All rights reserved.
//
// Auhtor:        Alexandre COSTANTINI
// Filename:      main.js
// Created on:    2019/09/26
// Last modified: 2021/05/07
// ============================================================================

// Vue
import _ from 'lodash';
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import { useStore } from './store';
import VueI18n from 'vue-i18n';
import { createPinia, PiniaVuePlugin } from 'pinia';

// cookies
import * as cookies from '@/cookies.js';

// moment
import Moment from 'moment/moment.js';
import MomentTimeZone from 'moment-timezone';

// Bootstrap
import BootstrapVue from 'bootstrap-vue';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

// FontAwesome
import { library, dom } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

// Highcharts
import Highcharts from 'highcharts';
import Stock from 'highcharts/modules/stock';
import HighchartsVue from 'highcharts-vue';
import exportingInit from 'highcharts/modules/exporting';

// FontAwesome: This will kick of the initial replacement
// of i to svg tags and configure a MutationObserver
dom.watch();

// FontAwesome: Import entire styles
library.add(fas);

// Main Cascading Style Sheet
import './main.css';

// Vue-JSON-CSV
import JsonCSV from 'vue-json-csv';

// Custom Fetch
import './fetch.js';

// Register Global Components
import SpinningMessage from '@/components/Misc/SpinningMessage.vue';
import ErrorMessage from '@/components/Misc/ErrorMessage.vue';
import TimePeriodSelector from '@/components/Misc/TimePeriodSelector.vue';
import CurrencySelector from '@/components/Misc/CurrencySelector.vue';
import ConfirmationForm from '@/components/Forms/Confirmation.vue';

Vue.component('SpinningMessage', SpinningMessage);
Vue.component('ErrorMessage', ErrorMessage);
Vue.component('TimePeriodSelector', TimePeriodSelector);
Vue.component('CurrencySelector', CurrencySelector);
Vue.component('ConfirmationForm', ConfirmationForm);
Vue.component('DownloadCsv', JsonCSV);
Vue.component('FontAwesomeIcon', FontAwesomeIcon);

// Load Vue.JS
Vue.config.productionTip = false;
Vue.use(BootstrapVue);
Vue.use(VueI18n);
Vue.use(PiniaVuePlugin);

// Moment
Vue.prototype.moment = Moment;
window.moment = Moment;
MomentTimeZone();

// Highcharts
Stock(Highcharts);
exportingInit(Highcharts);
Vue.use(HighchartsVue);

const pinia = createPinia();

// I18N
import messages from './lang/messages';
const locale = _.toLower(
  _.replace(navigator.language, /([a-zA-Z]+)(\\-)?.*/gi, '$1')
);
const i18n = new VueI18n({
  fallbackLocale: 'en',
  silentTranslationWarn: true,
  silentFallbackWarn: true,
  locale,
  messages,
});

const data = {
  utcTime: {
    type: Boolean,
    default: true,
  },
  currency: {
    type: String,
    default: 'usd',
  },
  portfolio: {
    type: String,
  },
  perfFromBalance: {
    type: Boolean,
    default: true,
  },
  configHasChanged: {
    type: Boolean,
    default: false,
  },
  archivedPortfolios: {
    type: Boolean,
    default: false,
  },
};

new Vue({
  router,
  useStore,
  i18n,
  pinia,
  data,
  watch: {
    utcTime (val) {
      cookies.setCookie('timeZone', val, 365);
    },
    currency (val) {
      cookies.setCookie('currency', val, 365);
    },
    perfFromBalance (val) {
      cookies.setCookie('perfFromBalance', val, 365);
    },
    archivedPortfolios (val) {
      cookies.setCookie('archivedPortfolios', val, 365);
    },
  },
  mounted () {
    this.$root.$data.utcTime = true;
    const timeZone = cookies.getCookie('timeZone');
    if (timeZone && timeZone !== '') {
      this.$root.$data.utcTime = timeZone === 'true';
    }

    this.$root.$data.currency = 'usd';
    const currency = cookies.getCookie('currency');
    if (currency && currency !== '') {
      this.$root.$data.currency = currency;
    }

    this.$root.$data.perfFromBalance = true;
    const perfFromBalance = cookies.getCookie('perfFromBalance');
    if (perfFromBalance && perfFromBalance !== '') {
      this.$root.$data.perfFromBalance = perfFromBalance === 'true';
    }

    this.$root.$data.archivedPortfolios = false;
    const archivedPortfolios = cookies.getCookie('archivedPortfolios');
    if (archivedPortfolios && archivedPortfolios !== '') {
      this.$root.$data.archivedPortfolios = archivedPortfolios === 'true';
    }

    this.$root.$data.configHasChanged = false;
  },
  render (h) {
    return h(App);
  },
}).$mount('#app');
