import { fetchWithTimeout } from '@/fetch.js';
import { URLPrefix } from '@/queries/common';

export const getExchanges = async () => {
  const response = await fetchWithTimeout(`${URLPrefix}exchanges`, {
    method: 'GET',
  });
  const jsonData = await response?.json();
  return jsonData.data;
};
