// ============================================================================
// Copyright 2021 ExoAplha. All rights reserved.
//
// Auhtor:        Alexandre COSTANTINI
// Filename:      fetch.js
// Created on:    2019/10/07
// Last modified: 2021/05/07
// ============================================================================

import _ from 'lodash';

export const computeBaseURL = () => {
  if (_.includes(window.location.origin, 'localhost:8080')) {
    return 'http://localhost:9090';
  } else {
    return window.location.origin;
  }
};

export const fetchWithTimeout = (uri, options = {}, time = 5000) => {
  // Lets set up our `AbortController`, and create a request options object
  // that includes the controller's `signal` to pass to `fetch`.
  const controller = new AbortController();
  const config = { ...options, signal: controller.signal };

  // Set a timeout limit for the request using `setTimeout`. If the body of this
  // timeout is reached before the request is completed, it will be cancelled.
  setTimeout(() => {
    controller.abort();
  }, time);

  const url = computeBaseURL() + uri;
  //console.log(url)
  return fetch(url, config)
    .then(async (response) => {
      // Because _any_ response is considered a success to `fetch`,
      // we need to manually check that the response is in the 200 range.
      // This is typically how I handle that.
      if (response.status === 401) {
        window.location.assign('/logout');
        return null;
      } else if (!response.ok) {
        const msg = await response.text();
        if (msg.length > 0) {
          throw new Error(msg);
        } else {
          throw new Error(`${response.status}: ${response.statusText}`);
        }
      }
      return response;
    })
    .catch((error) => {
      // When we abort our `fetch`, the controller conveniently throws a named
      // error, allowing us to handle them seperately from other errors.
      if (error.name === 'AbortError') {
        throw new Error('Server response timed out !');
      }
      throw new Error(error.message);
    });
};
