// ============================================================================
// Copyright 2021 ExoAplha. All rights reserved.
//
// Auhtor:        Alexandre COSTANTINI
// Filename:      messages.js
// Created on:    2019/13/03
// Last modified: 2021/05/07
// ============================================================================

export default {
  en: {
    loading: 'Loading...',
    ok: 'Ok',
    cancel: 'Cancel',
    yes: 'Yes',
    no: 'No',
    from: 'From',
    to: 'to',
    date: 'Date',
    portfolio: 'Portfolio',
    instrument: 'Instrument',
    benchmark: 'Benchmark',
    type: 'Type',
    side: 'Side',
    state: 'State',
    tag: 'Tag',
    sortBy: 'Sorting By',
    sortDirection: 'Sort Direction',
    ascending: 'Ascending',
    descending: 'Descending',
    lastname: 'Lastname',
    firstname: 'Firstname',
    downloadCSV: 'Download CSV',
    refreshPanel: 'Refresh panel content',
    selectCurrency: 'Select your currency conversion',
    all: 'All',
    total: 'Total',
    none: 'None',
    enabled: 'Enabled',
    disabled: 'Disabled',
    started: 'Started',
    paused: 'Paused',
    stopped: 'Stopped',
    true: 'True',
    false: 'False',
    language: 'Language',
    english: 'English',
    french: 'French',
    spanish: 'Spanish',
    german: 'German',
    password: 'Password',
    enterPassword: 'Enter your password',
    confirmation: 'Confirmation',
    immediate: 'Immediately',
  },
  fr: {
    loading: 'Chargement...',
    ok: 'Ok',
    cancel: 'Annuler',
    yes: 'Oui',
    no: 'Non',
    from: 'Du',
    to: 'au',
    date: 'Date',
    portfolio: 'Portefeuille',
    instrument: 'Instrument',
    benchmark: 'Référence',
    type: 'Type',
    side: 'Sens',
    state: 'Etat',
    tag: 'Étiquette',
    sortBy: 'Tri par',
    sortDirection: 'Direction de tri',
    ascending: 'Croissant',
    descending: 'Décroissant',
    lastname: 'Nom',
    firstname: 'Prénom',
    downloadCSV: 'Télécharger le CSV',
    refreshPanel: 'Actualiser le contenu du panneau',
    selectCurrency: 'Sélectionnez votre conversion de devise',
    all: 'Tout(es)',
    total: 'Total',
    none: 'Aucun',
    enabled: 'Activé',
    disabled: 'Désactivé',
    started: 'Démarré',
    paused: 'En pause',
    stopped: 'Arrété',
    true: 'Vrai',
    false: 'Faux',
    language: 'Langue',
    english: 'Anglais',
    french: 'Français',
    spanish: 'Espagnol',
    german: 'Allemand',
    password: 'Mot de passe',
    enterPassword: 'Tapez votre mot de passe',
    confirmation: 'Confirmation',
    immediate: 'Immédiatement',
  },
  es: {
    loading: 'Cargando...',
    ok: 'Ok',
    cancel: 'Cancelar',
    yes: 'Sí',
    no: 'No',
    from: 'Desde',
    to: 'a',
    date: 'Fecha',
    portfolio: 'Portafolio',
    instrument: 'Instrumento',
    benchmark: 'Referencia',
    type: 'Tipo',
    side: 'Dirección',
    state: 'Estado',
    tag: 'Etiqueta',
    sortBy: 'Ordenar por',
    sortDirection: 'Dirección de clasificación',
    ascending: 'Ascendente',
    descending: 'Descendente',
    lastname: 'Apellido',
    firstname: 'Primer nombre',
    downloadCSV: 'Descargar CSV',
    refreshPanel: 'Actualizar contenido del panel',
    selectCurrency: 'Seleccione su conversión de moneda',
    all: 'Todos/Todas',
    total: 'Total',
    none: 'Ninguno',
    enabled: 'Activado',
    disabled: 'Desactivado',
    started: 'Comenzó',
    paused: 'En pausa',
    stopped: 'Interrumpido',
    true: 'Verdadero',
    false: 'Falso',
    language: 'Idioma',
    english: 'Inglés',
    french: 'Francés',
    spanish: 'Español',
    german: 'Alemán',
    password: 'Contraseña',
    enterPassword: 'Ingresa tu contraseña',
    confirmation: 'Confirmación',
    immediate: 'Inmediatamente',
  },
  de: {
    loading: 'Wird geladen...',
    ok: 'Ok',
    cancel: 'Cancel',
    yes: 'Ja',
    no: 'Nein',
    from: 'Von',
    to: 'zu',
    date: 'Datum',
    portfolio: 'Portfolio',
    instrument: 'Instrument',
    benchmark: 'Benchmark',
    type: 'Art',
    side: 'Seite',
    state: 'Zustand',
    tag: 'Etikett',
    sortBy: 'Sortieren nach',
    sortDirection: 'Sortierrichtung',
    ascending: 'Aufsteigend',
    descending: 'Absteigend',
    lastname: 'Nachname',
    firstname: 'Vorname',
    downloadCSV: 'CSV herunterladen',
    refreshPanel: 'Panel-Inhalt aktualisieren',
    selectCurrency: 'Wählen Sie Ihre Währungsumrechnung',
    all: 'Alle',
    total: 'Gesamt',
    none: 'Keiner',
    enabled: 'Aktiviert',
    disabled: 'Deaktiviert',
    started: 'Gestartet',
    paused: 'Angehalten',
    stopped: 'Gestoppt',
    true: 'Wahr',
    false: 'Falsch',
    language: 'Sprache',
    english: 'Englisch',
    french: 'Französisch',
    spanish: 'Spanisch',
    german: 'Deutsche',
    password: 'Passwort',
    enterPassword: 'Geben Sie Ihr Passwort ein',
    confirmation: 'Bestätigung',
    immediate: 'Sofort',
  },
  zh: {
    loading: '加载...',
    ok: 'Ok',
    cancel: '取消',
    yes: '是的',
    no: '不',
    from: '从',
    to: '至',
    date: '日期',
    portfolio: '资产包',
    instrument: '仪器',
    benchmark: '基准',
    type: '类型',
    side: '边',
    state: '状态',
    tag: '标签',
    sortBy: '排序方式',
    sortDirection: '排序方向',
    ascending: '升序',
    descending: '降序',
    lastname: '姓',
    firstname: '名',
    downloadCSV: '下载 CSV',
    refreshPanel: '刷新面板内容',
    selectCurrency: '选择货币换算',
    all: '全部',
    total: '总数',
    none: '无',
    enabled: '启用了',
    disabled: '停用了',
    started: '已开始',
    paused: '已暂停',
    stopped: '已停止',
    true: '真的',
    false: '错误的',
    language: '语言',
    english: '英语',
    french: '法语',
    spanish: '西班牙语',
    german: '德语',
    password: '密码',
    enterPassword: '输入密码',
    confirmation: '确认',
    immediate: '立即地',
  },
};
