<!--===========================================================================
 Copyright 2021 ExoAplha. All rights reserved.

 Auhtor:        Alexandre COSTANTINI
 Filename:      ErrorMessage.vue
 Created on:    2019/10/01
 Last modified: 2021/05/07
===========================================================================!-->

<!-- prettier-ignore -->
<template>
  <div class="ea-alert my-2 text-center">
    <font-awesome-icon
      :icon="['fas', 'exclamation-triangle']"
      pull="left"
      size="2x" 
    />
    <strong style="margin-left: 10px">{{ errorMessage }}</strong>
  </div>
</template>

<script>
export default {
  name: 'ErrorMessage',
  props: {
    errorMessage: {
      type: String,
      required: true,
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.ea-alert {
  color: red;
  font-size: 14px;
  font-weight: bold;
  padding: 5px;
}
</style>
