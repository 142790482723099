var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-form',{attrs:{"inline":""}},[_c('label',{staticClass:"mx-1 my-0",attrs:{"size":"sm"}},[_vm._v(_vm._s(_vm.$t("from")))]),_c('b-form-datepicker',{attrs:{"value-as-date":"","placeholder":"","size":"sm","calendar-width":"250px","disabled":_vm.disabledCalendars,"readonly":_vm.readonlyCalendars,"locale":_vm.$i18n.locale,"min":_vm.min,"max":_vm.max,"date-format-options":{ year: 'numeric', month: 'long', day: 'numeric' },"initial-date":_vm.min,"hide-header":"","no-flip":"","weekday-header-format":"short"},on:{"input":_vm.onFromDateChanged},model:{value:(_vm.fromDate),callback:function ($$v) {_vm.fromDate=$$v},expression:"fromDate"}}),_c('label',{staticClass:"mx-1 my-0",attrs:{"size":"sm"}},[_vm._v(_vm._s(_vm.$t("to")))]),_c('b-form-datepicker',{attrs:{"value-as-date":"","placeholder":"","size":"sm","calendar-width":"250px","disabled":_vm.disabledCalendars,"readonly":_vm.readonlyCalendars,"locale":_vm.$i18n.locale,"min":_vm.min,"max":_vm.max,"date-format-options":{ year: 'numeric', month: 'long', day: 'numeric' },"initial-date":_vm.max,"hide-header":"","no-flip":""},on:{"input":_vm.onToDateChanged},model:{value:(_vm.toDate),callback:function ($$v) {_vm.toDate=$$v},expression:"toDate"}}),_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip",value:({
      title: _vm.$t('selectPredefinedPeriod'),
      placement: 'left',
      variant: 'light',
      trigger: 'hover',
      boundary: 'viewport',
      animation: false,
      delay: { show: 200, hide: 0 },
    }),expression:"{\n      title: $t('selectPredefinedPeriod'),\n      placement: 'left',\n      variant: 'light',\n      trigger: 'hover',\n      boundary: 'viewport',\n      animation: false,\n      delay: { show: 200, hide: 0 },\n    }"}]},[_c('b-dropdown',{staticClass:"mx-1 my-0",attrs:{"size":"sm"},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('font-awesome-icon',{attrs:{"icon":['fas', 'calendar-check']}})]},proxy:true}])},_vm._l((_vm.options),function(value,index){return _c('b-dropdown-item-button',{key:index,on:{"click":function($event){return _vm.onSelectedOption(value.value)}}},[_vm._v(" "+_vm._s(value.text)+" ")])}),1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }