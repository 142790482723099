import { fetchWithTimeout } from '@/fetch.js';
import { URLPrefix } from '@/queries/common';

export const getUsers = async () => {
  const response = await fetchWithTimeout(`${URLPrefix}users`, {
    method: 'GET',
  });
  const jsonData = await response?.json();
  return jsonData.data;
};

export const addUser = async (user) => {
  await fetchWithTimeout(`${URLPrefix}users/${user.login}`, {
    method: 'POST',
    //mode: "no-cors",
    headers: new Headers({ 'Content-Type': 'application/json' }),
    body: JSON.stringify({
      login: user.login,
      lastname: user.lastname,
      firstname: user.firstname,
      username: `${user.firstname} ${user.lastname}`,
      email: user.email,
      phone: user.phone,
      postalAddress: user.postalAddress,
      role: user.role,
      language: user.language,
      blocked: user.blocked,
      mensual: user.mensual,
      portfolios: user.portfolios,
    }),
  });

  if (user.password && user.password.length > 0) {
    await fetchWithTimeout(`${URLPrefix}users/${user.login}/password`, {
      method: 'post',
      //mode: "no-cors",
      headers: new Headers({ 'Content-Type': 'application/json' }),
      body: JSON.stringify(user.password),
    });
  }
};

export const modUser = async (user) => {
  await fetchWithTimeout(`${URLPrefix}users/${user.login}`, {
    method: 'PUT',
    //mode: "no-cors",
    headers: new Headers({ 'Content-Type': 'application/json' }),
    body: JSON.stringify({
      login: user.login,
      lastname: user.lastname,
      firstname: user.firstname,
      username: `${user.firstname} ${user.lastname}`,
      email: user.email,
      phone: user.phone,
      postalAddress: user.postalAddress,
      role: user.role,
      language: user.language,
      blocked: user.blocked,
      mensual: user.mensual,
      portfolios: user.portfolios,
    }),
  });

  if (user.password && user.password.length > 0) {
    await fetchWithTimeout(`${URLPrefix}users/${user.login}/password`, {
      method: 'POST',
      //mode: "no-cors",
      headers: new Headers({ 'Content-Type': 'application/json' }),
      body: JSON.stringify(user.password),
    });
  }
};

export const deleteUser = (user) => {
  return fetchWithTimeout(`${URLPrefix}users/${user.login}`, {
    //mode: "no-cors",
    method: 'DELETE',
  });
};
