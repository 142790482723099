// ============================================================================
// Copyright 2021 ExoAplha. All rights reserved.
//
// Auhtor:        Alexandre COSTANTINI
// Filename:      cookies.js
// Created on:    2020/01/06
// Last modified: 2021/05/07
// ============================================================================

import _ from 'lodash';

export function setCookie(cname, cvalue, exdays) {
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  const expires = `expires=${d.toUTCString()}`;
  document.cookie = `${cname}=${cvalue};${expires};path=/`;
}

export function getCookie(cname) {
  const name = `${cname}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const cookies = _.split(decodedCookie, ';');
  for (const cookie of cookies) {
    const c = _.trim(cookie);
    if (_.startsWith(c, name)) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
}

export function checkCookie(cname, cvalue) {
  const val = getCookie(cname);
  return val === cvalue;
}
