import _ from 'lodash';
import { fetchWithTimeout } from '@/fetch.js';
import { URLPrefix } from '@/queries/common';

export const getPortfolios = async () => {
  const response = await fetchWithTimeout(`${URLPrefix}portfolios`, {
    method: 'get',
  });
  const jsonData = await response?.json();
  return jsonData.data;
};

export const getPortfolioNames = async () => {
  const response = await fetchWithTimeout(`${URLPrefix}portfolioNames`, {
    method: 'get',
  });
  const jsonData = await response?.json();
  const { data } = jsonData;
  return _.filter(data, (val) => !_.startsWith(val.name, 'Template ')).sort();
};

export const getPortfolioInstruments = async (portfolio) => {
  const response = await fetchWithTimeout(
    `${URLPrefix}portfolios/${portfolio}/instruments`,
    { method: 'get' }
  );
  const jsonData = await response?.json();
  return jsonData.data;
};

export const getPortfolioConfig = async (portfolio) => {
  const response = await fetchWithTimeout(
    `${URLPrefix}portfolios/${portfolio}/config`,
    {
      method: 'get',
    },
    10000
  );
  const jsonData = await response?.json();
  return jsonData.data[0];
};

export const setPortfolioConfig = (portfolio, config) => {
  return fetchWithTimeout(
    `${URLPrefix}portfolios/${portfolio}/config`,
    {
      method: 'PUT',
      headers: new Headers({ 'Content-Type': 'application/json' }),
      body: JSON.stringify(config),
    },
    10000
  );
};

export const getPortfolioStatus = async (portfolio) => {
  const response = await fetchWithTimeout(
    `${URLPrefix}portfolios/${portfolio}/status`,
    {
      method: 'get',
    },
    10000
  );
  const jsonData = await response?.json();
  return jsonData.data[0];
};

export const getPortfolioPositions = async (portfolio) => {
  const response = await fetchWithTimeout(
    `${URLPrefix}portfolios/${portfolio}/openPositions`,
    {
      method: 'get',
    },
    10000
  );
  const jsonData = await response?.json();
  return jsonData.data;
};

export const getPortfolioAccount = async (portfolio) => {
  const response = await fetchWithTimeout(
    `${URLPrefix}portfolios/${portfolio}/marginAccount`,
    {
      method: 'get',
    },
    10000
  );
  const jsonData = await response?.json();
  return {
    ...jsonData.data[0],
    updateTime: jsonData.data[0].updateTime * 1000,
  };
};

export const getPortfolioTransfers = async (portfolio) => {
  const response = await fetchWithTimeout(
    `${URLPrefix}portfolios/${portfolio}/transfers`,
    {
      method: 'get',
    },
    10000
  );
  const jsonData = await response?.json();
  return _.map(jsonData.data, (data) => {
    return {
      ...data,
      timestamp: data.timestamp * 1000,
    };
  });
};
