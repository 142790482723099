<!--===========================================================================
 Copyright 2021 ExoAplha. All rights reserved.

 Auhtor:        Alexandre COSTANTINI
 Filename:      TimePeriodSelector.vue
 Created on:    2020/04/22
 Last modified: 2021/05/07
===========================================================================!-->

<!-- prettier-ignore -->
<template>
  <b-form inline>
    <label
      size="sm"
      class="mx-1 my-0"
    >{{ $t("from") }}</label>
    <b-form-datepicker
      v-model="fromDate"
      value-as-date
      placeholder
      size="sm"
      calendar-width="250px"
      :disabled="disabledCalendars"
      :readonly="readonlyCalendars"
      :locale="$i18n.locale"
      :min="min"
      :max="max"
      :date-format-options="{ year: 'numeric', month: 'long', day: 'numeric' }"
      :initial-date="min"
      hide-header
      no-flip
      weekday-header-format="short"
      @input="onFromDateChanged"
    />
    <label
      size="sm"
      class="mx-1 my-0"
    >{{ $t("to") }}</label>
    <b-form-datepicker
      v-model="toDate"
      value-as-date
      placeholder
      size="sm"
      calendar-width="250px"
      :disabled="disabledCalendars"
      :readonly="readonlyCalendars"
      :locale="$i18n.locale"
      :min="min"
      :max="max"
      :date-format-options="{ year: 'numeric', month: 'long', day: 'numeric' }"
      :initial-date="max"
      hide-header
      no-flip
      @input="onToDateChanged"
    />
    <div
      v-b-tooltip="{
        title: $t('selectPredefinedPeriod'),
        placement: 'left',
        variant: 'light',
        trigger: 'hover',
        boundary: 'viewport',
        animation: false,
        delay: { show: 200, hide: 0 },
      }"
    >
      <b-dropdown
        size="sm"
        class="mx-1 my-0"
      >
        <template #button-content>
          <font-awesome-icon :icon="['fas', 'calendar-check']" />
        </template>
        <b-dropdown-item-button
          v-for="(value, index) in options"
          :key="index"
          @click="onSelectedOption(value.value)"
        >
          {{ value.text }}
        </b-dropdown-item-button>
      </b-dropdown>
    </div>
  </b-form>
</template>

<script>
export default {
  name: 'TimePeriodSelector',
  props: {
    locale: {
      type: String,
      required: false,
      default: 'en',
    },
    min: {
      type: Date,
      required: false,
      default: () => {
        return new Date(2000, 0, 1, 0, 0, 0, 0);
      },
    },
    max: {
      type: Date,
      required: false,
      default: () => {
        return new Date();
      },
    },
    disabledCalendars: {
      type: Boolean,
      required: false,
      default: false,
    },
    readonlyCalendars: {
      type: Boolean,
      required: false,
      default: false,
    },
    initalFromDate: {
      type: Date,
      required: false,
      default: () => {
        const date = new Date();
        date.setUTCHours(0, 0, 0, 0);
        date.setMonth(date.getMonth() - 1);
        return date;
      },
    },
    initalToDate: {
      type: Date,
      required: false,
      default: () => {
        const date = new Date();
        date.setUTCHours(0, 0, 0, 0);
        return date;
      },
    },
    todayDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    yesterdayDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    thisWeekDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    lastWeekDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    thisMonthDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    lastMonthDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    thisQuarterDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    lastQuarterDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    thisYearDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    lastYearDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    resetToDefaultDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      fromDate: this.initalFromDate,
      toDate: this.initalToDate,
    };
  },
  computed: {
    options () {
      const dates = [];
      if (!this.todayDisabled) {
        dates.push({
          value: 0,
          text: this.$t('today'),
        });
      }
      if (!this.yesterdayDisabled) {
        dates.push({
          value: 1,
          text: this.$t('yesterday'),
        });
      }
      if (!this.thisWeekDisabled) {
        dates.push({
          value: 2,
          text: this.$t('thisWeek'),
        });
      }
      if (!this.lastWeekDisabled) {
        dates.push({
          value: 3,
          text: this.$t('lastWeek'),
        });
      }
      if (!this.thisMonthDisabled) {
        dates.push({
          value: 4,
          text: this.$t('thisMonth'),
        });
      }
      if (!this.lastMonthDisabled) {
        dates.push({
          value: 5,
          text: this.$t('lastMonth'),
        });
      }
      if (!this.thisQuarterDisabled) {
        dates.push({
          value: 6,
          text: this.$t('thisQuarter'),
        });
      }
      if (!this.lastQuarterDisabled) {
        dates.push({
          value: 7,
          text: this.$t('lastQuarter'),
        });
      }
      if (!this.thisYearDisabled) {
        dates.push({
          value: 8,
          text: this.$t('thisYear'),
        });
      }
      if (!this.lastYearDisabled) {
        dates.push({
          value: 9,
          text: this.$t('lastYear'),
        });
      }
      if (!this.resetToDefaultDisabled) {
        dates.push({
          value: 10,
          text: this.$t('reset'),
        });
      }
      return dates;
    },
  },
  methods: {
    onFromDateChanged () {
      if (this.fromDate.getTime() > this.toDate.getTime()) {
        this.toDate = new Date(this.fromDate.getTime());
        return;
      }
      this.$emit('dateChanged', {
        fromDate: this.convertToUTCDate(this.fromDate),
        toDate: this.convertToUTCDate(this.toDate),
      });
    },
    onToDateChanged () {
      if (this.fromDate.getTime() > this.toDate.getTime()) {
        this.fromDate = new Date(this.toDate.getTime());
        return;
      }
      this.$emit('dateChanged', {
        fromDate: this.convertToUTCDate(this.fromDate),
        toDate: this.convertToUTCDate(this.toDate),
      });
    },
    convertToUTCDate (date) {
      const d = new Date();
      d.setUTCFullYear(date.getFullYear(), date.getMonth(), date.getDate());
      d.setUTCHours(0, 0, 0, 0);
      return d;
    },
    onSelectedOption (value) {
      switch (value) {
        case 0:
          this.fromDate = this.today();
          this.toDate = this.tomorrow();
          break;
        case 1:
          this.fromDate = this.yesterday();
          this.toDate = this.today();
          break;
        case 2:
          this.fromDate = this.thisWeek();
          this.toDate = this.nextWeek();
          break;
        case 3:
          this.fromDate = this.lastWeek();
          this.toDate = this.thisWeek();
          break;
        case 4:
          this.fromDate = this.thisMonth();
          this.toDate = this.nextMonth();
          break;
        case 5:
          this.fromDate = this.lastMonth();
          this.toDate = this.thisMonth();
          break;
        case 6:
          this.fromDate = this.thisQuarter();
          this.toDate = this.nextQuarter();
          break;
        case 7:
          this.fromDate = this.lastQuarter();
          this.toDate = this.thisQuarter();
          break;
        case 8:
          this.fromDate = this.thisYear();
          this.toDate = this.nextYear();
          break;
        case 9:
          this.fromDate = this.lastYear();
          this.toDate = this.thisYear();
          break;
        case 10:
          this.fromDate = this.initalFromDate;
          this.toDate = this.initalToDate;
          break;
        default:
          break;
      }
      this.$emit('dateChanged', {
        fromDate: this.convertToUTCDate(this.fromDate),
        toDate: this.convertToUTCDate(this.toDate),
      });
    },
    today () {
      const d = new Date();
      d.setUTCFullYear(d.getUTCFullYear(), d.getUTCMonth(), d.getUTCDate());
      d.setUTCHours(0, 0, 0, 0);
      return d;
    },
    yesterday () {
      return new Date(this.today().getTime() - 86400000);
    },
    tomorrow () {
      return new Date(this.today().getTime() + 86400000);
    },
    thisWeek () {
      let weekday = this.today().getUTCDay() - 1;
      if (weekday < 0) {
        weekday = 6;
      }
      return new Date(this.today().getTime() - weekday * 86400000);
    },
    lastWeek () {
      return new Date(this.thisWeek().getTime() - 7 * 86400000);
    },
    nextWeek () {
      return new Date(this.thisWeek().getTime() + 7 * 86400000);
    },
    thisMonth () {
      const d = new Date();
      d.setUTCFullYear(d.getUTCFullYear(), d.getUTCMonth(), 1);
      d.setUTCHours(0, 0, 0, 0);
      return d;
    },
    lastMonth () {
      const d = new Date();
      let year = d.getUTCFullYear();
      let month = d.getUTCMonth();
      month--;
      if (month < 0) {
        month = 11;
        year--;
      }
      d.setUTCFullYear(year, month, 1);
      d.setUTCHours(0, 0, 0, 0);
      return d;
    },
    nextMonth () {
      const d = new Date();
      let year = d.getUTCFullYear();
      let month = d.getUTCMonth();
      month++;
      if (month > 11) {
        month = 0;
        year++;
      }
      d.setUTCFullYear(year, month, 1);
      d.setUTCHours(0, 0, 0, 0);
      return d;
    },
    thisQuarter () {
      const d = new Date();
      let year = d.getUTCFullYear();
      let month = d.getUTCMonth();
      month -= month % 3;
      if (month < 0) {
        month += 12;
        year--;
      }
      d.setUTCFullYear(year, month, 1);
      d.setUTCHours(0, 0, 0, 0);
      return d;
    },
    lastQuarter () {
      const d = new Date();
      let year = d.getUTCFullYear();
      let month = d.getUTCMonth();
      month -= month % 3;
      month -= 3;
      if (month < 0) {
        month += 12;
        year--;
      }
      d.setUTCFullYear(year, month, 1);
      d.setUTCHours(0, 0, 0, 0);
      return d;
    },
    nextQuarter () {
      const d = new Date();
      let year = d.getUTCFullYear();
      let month = d.getUTCMonth();
      month -= month % 3;
      month += 3;
      if (month > 11) {
        month -= 12;
        year++;
      }
      d.setUTCFullYear(year, month, 1);
      d.setUTCHours(0, 0, 0, 0);
      return d;
    },
    thisYear () {
      const d = new Date();
      d.setUTCFullYear(d.getUTCFullYear(), 0, 1);
      d.setUTCHours(0, 0, 0, 0);
      return d;
    },
    lastYear () {
      const d = new Date();
      const year = d.getUTCFullYear();
      d.setUTCFullYear(year - 1, 0, 1);
      d.setUTCHours(0, 0, 0, 0);
      return d;
    },
    nextYear () {
      const d = new Date();
      const year = d.getUTCFullYear();
      d.setUTCFullYear(year + 1, 0, 1);
      d.setUTCHours(0, 0, 0, 0);
      return d;
    },
  },
};
</script>

<i18n>
{
  "en": {
    "today": "Today",
    "yesterday": "Yesterday",
    "thisWeek": "This Week",
    "lastWeek": "Last Week",
    "thisMonth": "This Month",
    "lastMonth": "Last Month",
    "thisQuarter": "This Quarter",
    "lastQuarter": "Last Quarter",
    "thisYear":"This Year",
    "lastYear": "Last Year",
    "reset": "Reset to default",
    "selectPredefinedPeriod": "Select a predefined period of time"
  },
  "fr": {
    "today": "Aujourd'hui",
    "yesterday": "Hier",
    "thisWeek": "Cette semaine",
    "lastWeek": "Semaine Dernière",
    "thisMonth": "Ce mois",
    "lastMonth": "Mois Dernier",
    "thisQuarter": "Ce Trimestre",
    "lastQuarter": "Trimestre Dernier",
    "thisYear":"Cette Année",
    "lastYear": "Année dernière",
    "reset": "Par défaut",
    "selectPredefinedPeriod": "Sélectionnez une période de temps prédéfinie"
  },
  "es": {
    "today": "Hoy",
    "yesterday": "Ayer",
    "thisWeek": "Esta semana",
    "lastWeek": "Semana pasada",
    "thisMonth": "Este mes",
    "lastMonth": "Mes pasado",
    "thisQuarter": "Este cuarto",
    "lastQuarter": "Cuarto pasado",
    "thisYear":"Este año",
    "lastYear": "Año pasado",
    "reset": "por defecto",
    "selectPredefinedPeriod": "Seleccione un período de tiempo predefinido"
  },
  "de": {
    "today": "Heute",
    "yesterday": "Gestern",
    "thisWeek": "Diese Woche",
    "lastWeek": "Letzte Woche",
    "thisMonth": "Diesen Monat",
    "lastMonth": "Letzte Monate",
    "thisQuarter": "Dieses Quartal",
    "lastQuarter": "Letztes Quartal",
    "thisYear":"Dieses Jahr",
    "lastYear": "Letztes Jahr",
    "reset": "Zurücksetzen",
    "selectPredefinedPeriod": "Wählen Sie einen vordefinierten Zeitraum"
  },
  "zh": {
    "today": "当日",
    "yesterday": "昨日",
    "thisWeek": "本周",
    "lastWeek": "上周",
    "thisMonth": "本月",
    "lastMonth": "上月",
    "thisQuarter": "本季度",
    "lastQuarter": "上个季度",
    "thisYear":"今年",
    "lastYear": "去年",
    "reset": "默认",
    "selectPredefinedPeriod": "选则 预定义时间段"
  }
}
</i18n>
