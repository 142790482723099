// ============================================================================
// Copyright 2021 ExoAplha. All rights reserved.
//
// Auhtor:        Alexandre COSTANTINI
// Filename:      store.js
// Created on:    2019/09/26
// Last modified: 2021/05/07
// ============================================================================

import { defineStore } from 'pinia';
import * as Users from '@/queries/users';
import * as Common from '@/queries/common';
import * as Portfolio from './queries/portfolio';
import * as Exchanges from '@/queries/exchanges';

const getters = {
  portfolio: (state) => (name) => {
    if (state.portfolios !== null) {
      for (const portfolio of state.portfolios) {
        if (portfolio.name === name) {
          return portfolio;
        }
      }
    }
    return {};
  },
  portfolio_instruments: (state) => (name) => {
    if (state.portfolios !== null) {
      for (const portfolio of state.portfolios) {
        if (portfolio.name === name) {
          return portfolio.instruments;
        }
      }
    }
    return [];
  },
  has_dynamic_fee: (state) => (name) => {
    if (state.portfolios !== null) {
      for (const portfolio of state.portfolios) {
        if (portfolio.name === name && isNaN(parseFloat(portfolio.perfFee))) {
          return true;
        }
      }
    }
    return false;
  },
};

const actions = {
  getVersionInfo() {
    Common.getVersionInfo()
      .then((data) => {
        this.versioninfo = data;
      })
      .catch((error) => {
        this.versioninfo = null;
        this.error = error.message;
      });
  },

  getCurrentUser() {
    Common.getCurrentUser()
      .then((data) => {
        this.currentuser = data;
      })
      .catch((error) => {
        this.currentuser = null;
        // eslint-disable-next-line no-console
        console.error(error);
      });
  },

  getPortfolios() {
    Portfolio.getPortfolios()
      .then((data) => {
        this.portfolios = data;
      })
      .catch((error) => {
        this.portfolios = null;
        // eslint-disable-next-line no-console
        console.error(error);
      });
  },

  getUsers() {
    Users.getUsers()
      .then((data) => {
        this.users = data;
      })
      .catch((error) => {
        this.users = null;
        // eslint-disable-next-line no-console
        console.error(error);
      });
  },

  addUser(user) {
    return Users.addUser(user)
      .then(() => {
        if (this.users === null) {
          this.users = [];
        }
        this.users.push(user);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
      });
  },

  modUser(user) {
    return Users.modUser(user)
      .then(() => {
        for (let i = 0; i < this.users; i++) {
          if (this.users[i].login === user.login) {
            this.users[i] = user;
            return;
          }
        }
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
      });
  },

  deleteUser(user) {
    return Users.deleteUser(user)
      .then(() => {
        const newUsers = [];
        for (let i = 0; i < this.users.length; i++) {
          if (this.users[i].login !== user.login) {
            newUsers.push(this.users[i]);
          }
        }
        this.users = newUsers;
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
      });
  },

  getExchanges() {
    Exchanges.getExchanges()
      .then((data) => {
        this.exchanges = data;
      })
      .catch((error) => {
        this.exchanges = null;
        // eslint-disable-next-line no-console
        console.error(error);
      });
  },
};

export const useStore = defineStore('main', {
  state: () => ({
    versioninfo: {
      company: '',
      address: '',
      version: '',
      build: '',
    },
    currentuser: {},
    portfolios: [],
    users: [],
    exchanges: [],
  }),
  getters,
  actions,
});
