import { render, staticRenderFns } from "./ErrorMessage.vue?vue&type=template&id=0b1f4226&scoped=true"
import script from "./ErrorMessage.vue?vue&type=script&lang=js"
export * from "./ErrorMessage.vue?vue&type=script&lang=js"
import style0 from "./ErrorMessage.vue?vue&type=style&index=0&id=0b1f4226&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0b1f4226",
  null
  
)

export default component.exports