<!--===========================================================================
 Copyright 2021 ExoAplha. All rights reserved.

 Auhtor:        Alexandre COSTANTINI
 Filename:      Confirmation.vue
 Created on:    2020/07/21
 Last modified: 2021/05/07
===========================================================================!-->

<!-- prettier-ignore -->
<template>
  <b-modal
    :id="id"
    hide-header-close
    no-close-on-backdrop
    body-class="position-static"
    :title-html="dTitleHtml.length > 0 ? dTitleHtml : ''"
    :title="dTitle.length > 0 ? dTitle : ''"
    :centered="centered"
    :scrollable="scrollable"
    :ok-only="okOnly"
    :button-size="buttonSize"
    :body-text-variant="bodyTextVariant"
    :header-text-variant="headerTextVariant"
    :ok-variant="okVariant"
    :size="size"
    @ok="handleOk"
  >
    <div class="modal-body">
      {{ dMessage }}
    </div>
    <slot />
  </b-modal>
</template>

<script>
import _ from 'lodash';

export default {
  name: 'ConfirmationForm',
  props: {
    id: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: false,
      default: '',
    },
    titleHtml: {
      type: String,
      required: false,
      default: '',
    },
    message: {
      type: String,
      required: false,
      default:
        "Please confirm this action by entering your password below and press the 'Ok' button.",
    },
    callback: {
      type: Function,
      required: false,
      default: _.noop,
    },
    buttonSize: {
      type: String,
      required: false,
      default: 'md',
    },
    bodyTextVariant: {
      type: String,
      required: false,
      default: 'dark',
    },
    headerTextVariant: {
      type: String,
      required: false,
      default: 'dark',
    },
    okVariant: {
      type: String,
      required: false,
      default: 'dark',
    },
    size: {
      type: String,
      required: false,
      default: 'md',
    },
    centered: {
      type: Boolean,
      required: false,
      default: false,
    },
    scrollable: {
      type: Boolean,
      required: false,
      default: false,
    },
    okOnly: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      dTitleHtml: this.titleHtml,
      dTitle: this.title,
      dMessage: this.message,
      dCallback: this.callback,
    };
  },
  methods: {
    handleOk() {
      if (this.dCallback) {
        this.dCallback();
      }
    },
    setTitleHtml(t) {
      this.dTitleHtml = t;
    },
    setTitle(t) {
      this.dTitle = t;
    },
    setMessage(m) {
      this.dMessage = m;
    },
    setCallback(f) {
      this.dCallback = f;
    },
  },
};
</script>
